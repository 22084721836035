import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Conditions Générales d'Utilisation`}</h2>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Dernière actualisation : 4 novembre 2021`}</em></p>
    </blockquote>
    <h3>{`Article 1. Identification`}</h3>
    <p>{`Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») sont proposées par ENIA, société par actions
simplifiée au capital de 1090,80 €, immatriculée au RCS de Nanterre sous le numéro 878 058 346 dont le siège social est
sis au 11 rue Rouget de Lisle, 92600 Asnières sur Seine (ci-après « ENIA »).`}</p>
    <p>{`ENIA est propriétaire de la Plateforme accessible via l’adresse URL `}<a parentName="p" {...{
        "href": "http://www.enia.green"
      }}>{`www.enia.green`}</a>{` (ci-après la « Plateforme »).`}</p>
    <p>{`La Plateforme est hébergée par OVH et le Directeur de publication est Monsieur Jacques-Arthur de Saint Germain.`}</p>
    <p>{`Le Propriétaire de la Plateforme et le délégué à la protection des données est Monsieur Jacques-Arthur de Saint
Germain.`}</p>
    <h3>{`Article 2. Objet`}</h3>
    <p>{`Les présentes Conditions Générales d’Utilisation (« CGU ») sont le lien contractuel entre l’Utilisateur et ENIA et ont pour
objet de définir les conditions d’inscription et d’utilisation de la Plateforme. L’accès et l’utilisation de la Plateforme
impliquent l’acceptation sans réserve par l’Utilisateur des CGU.`}</p>
    <p>{`ENIA se réserve le droit de modifier à tout moment les CGU, CG et Politique de Confidentialité. La dernière version en
vigueur est accessible sur la Plateforme. L’Utilisateur qui ne souhaiterait pas accepter toute ou partie des CGU doit
renoncer à utiliser la Plateforme.`}</p>
    <p>{`ENIA se réserve la possibilité d’interrompre ou de suspendre momentanément l’accès à la Plateforme ou d’en modifier
l’accès pour des raisons de maintenance, sans que cela ouvre droit à une quelconque indemnité au profit de l’Utilisateur.`}</p>
    <p>{`Pour utiliser la Plateforme, l’Utilisateur doit disposer d’un matériel informatique et d’un accès à Internet. Avant toute
utilisation, l’Utilisateur doit s’assurer que son matériel informatique est compatible avec la Plateforme. En aucun cas, la
responsabilité d’ENIA ne pourra être engagée en cas d’impossibilité d’accéder à la Plateforme.`}</p>
    <h3>{`Article 3. Accès – Utilisation de la Plateforme`}</h3>
    <h4>{`3.1. Accès`}</h4>
    <p>{`L’accès à la Plateforme nécessite pour l’Utilisateur de disposer d’un accès Internet. Tous les coûts nécessaires à
l’équipement, à la connexion à Internet sont à l’entière charge de l’Utilisateur. L’utilisation de la Plateforme est
conditionnée à l'ouverture d'un compte personnel et non cessible sur la Plateforme lequel permet d’accéder à son
espace.`}</p>
    <p>{`Pour créer un compte, l’Utilisateur doit communiquer un certain nombre d’informations par le biais d’un formulaire lors de
la création de son espace (nom de la société, nom et prénom, numéro de TVA, email, adresse postale, numéro de
téléphone, RIB, etc.) et choisir un identifiant et un mot de passe pour accéder à la Plateforme que l’Utilisateur s’engage à
conserver secrets et ne divulguer à aucun tiers. En cas de perte, de détournement ou d'utilisation frauduleuse,
l’Utilisateur s’engage à en avertir immédiatement ENIA.`}</p>
    <p>{`L’Utilisateur s’engage à vérifier, mettre à jour et garantir l’exactitude, la complétude et la sincérité des informations
indiquées. Si ENIA le demande, l’Utilisateur doit être en mesure de lui fournir copie de tous documents d’identification et
autorisations.`}</p>
    <p>{`ENIA se réserve le droit de refuser une demande d’inscription à la Plateforme en présence d’un Utilisateur ne se
conformant pas aux CGU, CG ou ne renseignant pas intégralement ou de bonne foi les informations demandées.`}</p>
    <h4>{`3.2. Pour le Fournisseur de matières`}</h4>
    <p>{`Pour procéder à la vente d’un Produit, le Fournisseur doit se connecter sur son espace et publier ses Fiches Produits
précisant certaines informations (dénomination sociale, coordonnées GPS, catégorie de produit, nature du produit,
quantité, date de disponibilité du produit, mode de livraison et pouvoir méthanogène). Le Fournisseur garantit l’exactitude
des informations indiquées et reconnaît être le seul responsable de la complétude de la description et de la conformité du
Produit à la description.`}</p>
    <p>{`Via cet Espace, le Fournisseur pourra accéder à ses Produits proposés, visualiser ses transactions en cours et passées
et répondre aux éventuelles demandes et/ou réclamations d’Acheteur.`}</p>
    <h4>{`3.3. Pour l’Acheteur`}</h4>
    <p>{`Pour procéder à l’achat de matières organiques (« Produit »), l’Acheteur doit se connecter sur son espace afin d’accéder
aux gisements proches de chez lui.`}</p>
    <p>{`Pour passer Commande, l’Acheteur doit être connecté, sélectionner le ou les Produits choisis, les ajouter à son panier
virtuel, vérifier la Commande et le prix total, choisir le mode de livraison (livraison ou récupération) et valider la
Commande.`}</p>
    <p>{`S’il souhaite valider sa Commande, il devra cliquer sur le bouton de validation. Il sera alors redirigé sur une page dans
laquelle il devra remplir les champs de formulaire de commande (contenant les informations nécessaires au bon
déroulement de la commande) et procéder au paiement.`}</p>
    <p>{`Le paiement effectif du prix indiqué valide la Commande. La vente est néanmoins soumise à la disponibilité du Produit.
Le Fournisseur confirmera le stock disponible et donc la vente, préparera le Produit pour sa livraison ou récupération.`}</p>
    <p>{`L’Acheteur recevra un email de confirmation de l’enregistrement de sa Commande une fois la Commande confirmée par
le Fournisseur ainsi qu’une facture. La validation de la commande vaut acceptation expresse et irrévocable des prix, des
descriptions des Produits et des CGU / CGV.`}</p>
    <h3>{`Article 4. Durée de l’inscription`}</h3>
    <p>{`L’inscription de l’Utilisateur est valable pour une durée indéterminée.`}</p>
    <p>{`Chaque Utilisateur est libre de fermer son compte et ses données sur la Plateforme. Pour ceci, il doit adresser un e-mail
à ENIA indiquant qu’il souhaite supprimer son compte. Aucune récupération de ses données ne sera alors possible.`}</p>
    <h3>{`Article 5. Engagements et responsabilité`}</h3>
    <h4>{`5.1. De l’Utilisateur`}</h4>
    <p>{`L’Utilisateur déclare avoir pris connaissance des caractéristiques et des limites d’Internet décrites ci-dessous :`}</p>
    <ul>
      <li parentName="ul">{`les transmissions de données sur Internet ne bénéficient que d’une fiabilité technique relative et que nul ne peut
garantir le bon fonctionnement d’Internet ;`}</li>
      <li parentName="ul">{`ENIA a pris d’importantes mesures de sécurisation de l’accès à la Plateforme, selon une obligation de moyen,`}</li>
    </ul>
    <p>{`mais que les données circulant sur Internet peuvent faire l’objet de détournements, et qu’ainsi la communication
de mots de passe, codes confidentiels, et plus généralement, de toute information à caractère sensible est
effectuée par l’Utilisateur à ses risques et périls.`}</p>
    <p>{`L’Utilisateur en utilisant la Plateforme s’engage à respecter les législations en vigueur et s’engage notamment à ne pas :
violer les droits d'un tiers, publier de contenus préjudiciables, menaçants, diffamatoires, dénigrants, haineux ou incitant à
la haine, communiquer d’informations ou de contenu faux, incorrects ou trompeurs, à visée publicitaire, usurper l’identité
d’une personne ou d’une entité.`}</p>
    <p>{`L’Utilisateur est seul responsable de l'utilisation et de l'accès à son espace. ENIA ne pourra être tenue responsable d’une
quelconque perte ou dommage que l’Utilisateur aurait subi à la suite de l'utilisation non autorisée de son compte par
autrui, et ce même à son insu.`}</p>
    <p>{`ENIA se réserve le droit d'annuler ou de suspendre à tout moment l’accès à la Plateforme à un Utilisateur en cas
d’atteinte aux CGV / CGU, aux droits de tiers, ou si l’Utilisateur est susceptible de causer préjudice aux intérêts d’ENIA,
d'autres Utilisateurs ou de tiers.`}</p>
    <h4>{`5.2. D’ENIA`}</h4>
    <p>{`La Plateforme fournit à l'Utilisateur des informations à titre indicatif, avec des imperfections, erreurs, omissions,
inexactitudes et autres ambivalences susceptibles d'exister.`}</p>
    <p>{`ENIA fera son possible pour que la Plateforme soit accessible sur Internet selon une obligation de moyen.`}</p>
    <p>{`ENIA fera ses meilleurs efforts pour que la Plateforme fonctionne de manière fiable et continue, mais ne peut garantir le
fonctionnement ininterrompu et sans erreur. ENIA s’engage à prendre toute précaution raisonnable pour assurer la
protection de la Plateforme et à en sécuriser l’accès. L’Utilisateur est informé des limites inhérentes à Internet et fait une
utilisation de la Plateforme à ses risques et périls. Cet accès peut toutefois être interrompu, notamment en cas de
maintenance et de mise à jour, de panne ou d'autres raisons techniques.`}</p>
    <p>{`ENIA décline donc toute responsabilité en cas de pannes, d'interruptions ou d'erreurs présentes sur la Plateforme, de
même que pour les dommages directs accessoires ou indirects ou tout dommage qui pourrait résulter de l’utilisation ou
de l’impossibilité d’utiliser la Plateforme.`}</p>
    <p>{`ENIA ne saurait voir sa responsabilité engagée à raison des Contenus rédigés par les Utilisateurs, sauf si elle ne les
rendait pas inaccessibles après avoir été informée de leur illicéité.`}</p>
    <p>{`En tout état de cause, ENIA ne pourra en aucun cas être tenu responsable : (i) de tout dommage direct ou indirect (pertes
de profits, manque à gagner, pertes de clientèle, de données, etc.) pouvant résulter de l'utilisation de la Plateforme , ou
de l'impossibilité de son utilisation et/ou (ii) d'un dysfonctionnement, d'une indisponibilité d'accès, d'une mauvaise
utilisation, d'une mauvaise configuration de l'ordinateur de l'Utilisateur, ou encore de l'emploi d'un navigateur peu usité
par l'Utilisateur.`}</p>
    <h3>{`Article 6. Respect des droits de propriété et de propriété intellectuelle`}</h3>
    <p>{`Sauf mention contraire, les éléments accessibles sur la Plateforme tels que les bases de données, les outils de gestion,
les logiciels utilisés, l’agencement, les logos, les marques, les photographies, les textes et plus généralement l’ensemble
des données et informations mises à disposition sont la propriété pleine, entière et exclusive de ENIA et/ou sont utilisés
par ENIA sur la Plateforme avec l’autorisation de leur propriétaire. Ces éléments sont des œuvres protégées par des
droits de propriété intellectuelle.`}</p>
    <p>{`L'Utilisateur s’interdit notamment :`}</p>
    <ul>
      <li parentName="ul">{`de copier, d’enregistrer, de reproduire, décompiler, désassembler en tout ou partie la Plateforme par n’importe
quel moyen et sous n’importe quelle forme ;`}</li>
      <li parentName="ul">{`de vendre, céder ou mettre à disposition de tiers tout ou partie de la Plateforme ;`}</li>
      <li parentName="ul">{`de supprimer les mentions d’identification et de propriété de l’Éditeur;`}</li>
      <li parentName="ul">{`d’utiliser ou copier la Plateforme de toute autre manière non expressément autorisée par les CG.`}</li>
    </ul>
    <p>{`Son inscription ne lui confère qu’un droit d’usage privé personnel, non cessible, non transférable, et non exclusif d’utiliser
la Plateforme.`}</p>
    <p>{`Toute utilisation étrangère à l’utilisation personnelle des éléments de la Plateforme sans autorisation est interdite et sera
susceptible d’être caractérisée comme des actes de contrefaçon.`}</p>
    <p>{`Tout Utilisateur qui se rendrait coupable de contrefaçon serait susceptible de voir son accès à la Plateforme supprimé
sans préavis ni indemnité et sans que cette exclusion ne puisse lui être constitutive d’un dommage, sans réserve
d’éventuelles poursuites judiciaires ultérieures à son encontre, à l’initiative d’ENIA.`}</p>
    <h3>{`Article 7. Cookies - Balises tags internet`}</h3>
    <p>{`Les dispositions concernant les cookies et balises tags internet sont situées dans un document annexe intitulé « Politique
de Confidentialité ».`}</p>
    <h3>{`Article 8. Liens hypertextes`}</h3>
    <p>{`La Plateforme contient un certain nombre de liens hypertextes vers d’autres sites. Cependant, ENIA n’a pas la possibilité
de vérifier le contenu des sites puisque ENIA n’exerce aucun contrôle sur ces liens, et n’assumera en conséquence
aucune responsabilité de ce fait. Ainsi, ENIA ne donne aucune garantie concernant notamment la véracité, la qualité, la
complétude et l’exhaustivité du contenu des sites internet indexés et leurs difficultés d’accès et de fonctionnement. De
même, la responsabilité d’ENIA ne saurait être engagée si la visite, par l’Utilisateur, de l’un de ces sites, lui causait un
préjudice.`}</p>
    <h3>{`Article 9. Les données personnelles`}</h3>
    <p>{`Les dispositions concernant la protection des données personnelles telles que issues de la Loi Informatique et Libertés
en date du 6 janvier 1978 et modifiée par le Règlement européen sur la protection des données personnelles (« RGPD »)
sont situées dans un document annexe intitulé « Politique de Confidentialité ».`}</p>
    <h3>{`Article 10. Service Client`}</h3>
    <p>{`Le service d'assistance de la Plateforme est accessible par courrier électronique à l’adresse suivante :
`}<a parentName="p" {...{
        "href": "mailto:contact@enia.green"
      }}>{`contact@enia.green`}</a>{` ou par courrier postal à l’adresse indiquée dans les mentions légales. L’assistance téléphonique
peut être contactée au 01 84 20 33 65 (numéro non surtaxé).`}</p>
    <h3>{`Article 11. Compétence - Litige`}</h3>
    <p>{`Les CGU sont régies par et soumises au droit français. Sauf dispositions d’ordre public, tout litige relatif à leur
interprétation et/ou à leur exécution relève des juridictions françaises.`}</p>
    <p>{`Tout différend né de l’interprétation ou exécution des CG ou toute réclamation fera l’objet d’une tentative de règlement
amiable avant toute action judiciaire : soit directement entre les Parties, soit par l’intermédiaire d’un médiateur.
A défaut de solution amiable intervenue, le litige sera soumis aux tribunaux compétents, selon les dispositions légales en
vigueur.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      